import styled from '@emotion/styled';
import {graphql, Link} from 'gatsby';
import React from 'react';
import SEO from '../components/seo';
import {rhythm, scale} from '../utils/typography';

import Layout from '../components/layout';

interface Props {
  readonly data: PageQueryData;
}

export default class Tags extends React.Component<Props> {
  render() {
    const {data} = this.props;
    const group = data.allMarkdownRemark && data.allMarkdownRemark.group;

    return (
      <Layout>
        <SEO title="Tags" />
        <article>
          <h2>All tags</h2>
          <Div className={`page-content`}>
            {group &&
              group.map(
                (tag) =>
                  tag && (
                    <Div key={tag.fieldValue}>
                      <div>
                        <Link to={`/tags/${tag.fieldValue}/`} style={{...scale(1 / 5)}}>
                          {tag.fieldValue}
                        </Link>
                      </div>
                      <small>
                        {tag.totalCount} post
                        {tag.totalCount === 1 ? '' : 's'}
                      </small>
                    </Div>
                  ),
              )}
          </Div>
        </article>
      </Layout>
    );
  }
}

interface PageQueryData {
  allMarkdownRemark: {
    group: {
      fieldValue: string;
      totalCount: number;
    }[];
  };
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {published: {ne: false}}}
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

const Div = styled.div`
  margin-bottom: ${rhythm(2 / 3)};
`;
